<template>
  <div>
    <skewed-top></skewed-top>

    <div class="container mt-4">
      <h1>UL HPC Team</h1>
      <hr />

      <h4>HPC Management</h4>

      <div class="container">
        <div class="row text-center">
          <staff-card
            imgUrl="/assets/img/people/pascal.bouvry.jpg"
            name="Prof. Dr. Pascal Bouvry"
            role="Dean of the FSTM"
            :canFlip="false"
            >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/julien.schleich.jpg"
            name="Dr. Julien Schleich"
            role="Research Scientist"
            :canFlip="false"
          >
          </staff-card>
        </div>
      </div>

      <h4>HPC Platform</h4>
      <div class="container">
        <div class="row text-center">

          <staff-card
            imgUrl="/assets/img/people/matteo.barborini.jpg"
            name="Dr. Matteo Barborini"
            role="Postdoctoral Researcher"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/hyacinthe.cartiaux.jpg"
            name="Hyacinthe Cartiaux"
            role="Infrastructure and Architecture Engineer"
            :canFlip="false"
            >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/oscar.castrolopez.jpg"
            name="Dr. Oscar Jesus Castro Lopez"
            role="Research Associate"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/arnaud.glad.jpg"
            name="Dr. Arnaud Glad"
            role="Postdoctoral Researcher"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/georgios.kafanas.jpg"
            name="Dr. Georgios Kafanas"
            role="Postdoctoral Researcher"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/mike.massonnet.jpg"
            name="Mike Massonnet"
            role="Infrastructure and Architecture Engineer"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/pierrick.pochelu.jpg"
            name="Dr. Pierrick Pochelu"
            role="Postdoctoral Researcher"
            :canFlip="false"
            >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/ludovic.schoepps.jpg"
            name="Ludovic Schoepps"
            role="Infrastructure and Architecture Engineer"
            :canFlip="false"
            >
          </staff-card>
        </div>
      </div>

      <h4>
        Alumni / Past HPC staff
        <small class="text-muted">(In alphabetical order)</small>
      </h4>

      <div class="container">
        <div class="row text-center">

          <staff-card
            imgUrl="/assets/img/people/fotis.georgatos.jpg"
            name="Fotis Georgatos"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/emmanuel.kieffer.png"
            name="Dr. Emmanuel Kieffer"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/loizos.koutsantonis.jpg"
            name="Dr. Loizos Koutsantonis"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/ezhilmathi.krishnasamy.jpg"
            name="Dr. Ezhilmathi Krishnasamy"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/jflf.jpg"
            name="Jean-François Le Fillâtre"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/abatcha.olloh.jpg"
            name="Abatcha Olloh"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/clement.parisot.jpg"
            name="Clément Parisot"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/johnatan.pecero.jpg"
            name="Dr. Johnatan Eliabeth PECERO SANCHEZ"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/sarah.peter.jpg"
            name="Sarah Peter"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/frederic.pinel.jpg"
            name="Dr. Frederic Pinel"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/valentin.plugaru.jpg"
            name="Valentin Plugaru"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/arlyne.vandeventer.jpg"
            name="Arlyne Vandeventer"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/teddy.valette.jpg"
            name="Teddy Valette"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

          <staff-card
            imgUrl="/assets/img/people/sebastien.varrette.jpg"
            name="Dr. Sebastien Varrette"
            colRepartition="3"
            :canFlip="false"
          >
          </staff-card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from "bootstrap-vue/esm/components/image";

export default {
  name: "components",
  components: {
    BImg,
  },
  metaInfo: {
    title: "Team",
  },
};
</script>
<style>
</style>
