<template>
  <div>
    <skewed-top></skewed-top>

    <div class="container mt-4">
      <h1>Complementary Services</h1>
      <hr />

      <div class="card-columns text-center">
        <tech-card
          imgUrl="https://gitlab.uni.lu/uploads/-/system/appearance/logo/1/gitlab-logo-gray-rgb-small.png"
          name="Gitlab @ Uni.lu"
          description="End-to-end software development platform"
          website="https://hpc-docs.uni.lu/services/#gitlab-unilu"
        >
        </tech-card>

        <tech-card
          imgUrl="https://upload.wikimedia.org/wikipedia/commons/f/ff/Logo_Etherpad.png"
          name="EtherPad"
          description="Web-based collaborative real-time editor"
          website="https://hpc-docs.uni.lu/services/#etherpad"
        >
        </tech-card>

        <tech-card
          imgUrl="https://privatebin.info/img/logo.png"
          name="PrivateBin"
          description="Minimalist, open source online pastebin where the server has zero knowledge of pasted data"
          website="https://hpc-docs.uni.lu/services/#privatebin"
        >
        </tech-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "components",
  components: {},
  metaInfo: {
    title: 'Services'
  },
};
</script>
<style>
</style>
