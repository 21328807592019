<template>
  <div>
    <skewed-top></skewed-top>

    <div class="container mt-4">
      <h1>Devops Technologies</h1>
      <hr />

      <div class="card-columns text-center">
        <tech-card
          imgUrl="https://puppet.com/images/logos/puppet-logo-black.svg"
          name="Puppet"
          description="Configuration management tool"
          website="https://puppet.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://upload.wikimedia.org/wikipedia/commons/2/24/Ansible_logo.svg"
          name="Ansible"
          description="Provisioning and configuration tool"
          website="https://www.ansible.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://www.brightcomputing.com/hs-fs/hubfs/logo-transparent.png?width=380&name=logo-transparent.png"
          name="Bright Computing"
          description="Cluster deployement and management stack"
          website="https://www.brightcomputing.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://bluebanquise.com/images/BlueBanquise_logo_large.svg"
          name="BlueBanquise"
          description="Cluster deployement and management stack"
          website="https://bluebanquise.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://www.redhat.com/cms/managed-files/Logo-redhat-color-375.png"
          name="Red Hat"
          description="Operating system"
          website="https://www.redhat.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Slurm_logo.svg/590px-Slurm_logo.svg.png"
          name="Slurm"
          description="Job scheduling system"
          website="https://www.schedmd.com/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://easybuilders.github.io/easybuild-tutorial/img/easybuild_logo_alpha.png"
          name="EasyBuild"
          description="Software build and installation framework"
          website="https://easybuild.io/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://www.linux-kvm.org/kvmless/kvmbanner-logo3.png"
          name="KVM"
          description="Linux full virtualization tool"
          website="https://www.linux-kvm.org/page/Main_Page"
        >
        </tech-card>

        <tech-card
          imgUrl="https://upload.wikimedia.org/wikipedia/commons/3/38/Prometheus_software_logo.svg"
          name="Prometheus"
          description="Monitoring database"
          website="https://prometheus.io/"
        >
        </tech-card>

        <tech-card
          imgUrl="https://grafana.com/static/assets/internal/grafana_logo-web-dark.svg"
          name="Grafana"
          description="Analytics and interactive visualization web application"
          website="https://grafana.com/"
        >
        </tech-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "components",
  components: {},
  metaInfo: {
    title: 'DevOps Technologies'
  },
};
</script>
<style>
</style>
