<template>
  <div>
    <skewed-top></skewed-top>
    <div class="container mt-4">
      <div
        class="callout"
        :class="[headingCallout && `callout-${headingCallout.status}`]"
        v-if="headingCallout.text"
      >
        <h4 class="alert-heading">
          <i class="fa fa-exclamation-circle"></i> {{ headingCallout.title }}
        </h4>
        <markdown-page
          v-if="headingCallout.text"
          :content="headingCallout.text"
          classes=""
        ></markdown-page>
      </div>

      <status-table
        title="Global Services"
        :status="categoryStatus.global"
        :services="services.global"
      ></status-table>

      <div class="row">
        <div class="col-md-6">
          <status-table
            title="Iris"
            :status="categoryStatus.iris"
            :services="services.iris"
          ></status-table>
        </div>

        <div class="col-md-6">
          <status-table
            title="Aion"
            :status="categoryStatus.aion"
            :services="services.aion"
          ></status-table>
        </div>
      </div>

      <status-table
        title="Global Filesystems"
        :status="categoryStatus.storage"
        :services="services.storage"
      ></status-table>

      <status-table
        title="Additional Services"
        :status="categoryStatus.misc"
        :services="services.misc"
      ></status-table>

      <status-table
        title="Infrastructure Services"
        :status="categoryStatus.infra"
        :services="services.infra"
      ></status-table>

      <div class="mt-5">
        <h3>Legend :</h3>
        <ul>
          <li><status-indicator></status-indicator> : Unknown</li>
          <li><status-indicator positive></status-indicator> : Service UP</li>
          <li><status-indicator intermediary></status-indicator> : Service DEGRADED</li>
          <li><status-indicator negative></status-indicator> : Service DOWN</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import StatusTable from "../../components/StatusTable.vue";
import axios from "axios";

const STATUS_PROJECT_URL = "https://gitlab.com/api/v4/projects/56665873/issues";
const STATUS_PROJECT_ACCESS_TOKEN = "glpat-BDwXp9rqyphQpbmGBDP-"; // this can be pushed in plain text, it is a read-only token

export default {
  name: "components",
  components: {
    StatusTable,
  },
  metaInfo: {
    title: "Status",
  },
  data() {
    return {
      // Define global structure for banner
      // Status can have 'info', 'success', 'warning' or 'danger'
      headingCallout: {},
      // Define categories status
      // Each can have 'active', 'positive', 'intermediary' or 'negative'
      categoryStatus: {},
      // Define services structure
      // Each services in categories can must have 2 fields: 'status' and 'details'
      // Each services can have a facultative field: 'cname' to modify the displayed name of the service
      // Status can have 'active', 'positive', 'intermediary' or 'negative'
      services: {},
      // Declare timer which will auto refresh status data
      autoRefreshTimer: null,
    };
  },
  created() {
    this.updateStatusData();
    // Auto-refresh of data every 10 minutes
    this.autoRefreshTimer = setInterval(this.updateStatusData, 10*30*1000);
  },
  beforeDestroy() {
    // Destroy timer to prevent useless refresh of status data
    clearInterval(this.autoRefreshTimer);
  },
  methods: {
    clearStatusData() {
      // Provision global objects with default data
    this.headingCallout= {
        status: "",
        title: "",
        text: "",
      }

    this.categoryStatus= {
        global: "",
        iris: "",
        aion: "",
        storage: "",
        misc: "",
        infra: "",
      }

    this.services = {
        global: {
          Authentication: { status: "", details: "" },
          DNS: { status: "", details: "" },
        },
        iris: {
          SSH: { status: "", details: "" },
          SLURM: { status: "", details: "" },
        },
        aion: {
          SSH: { status: "", details: "" },
          SLURM: { status: "", details: "" },
        },
        storage: {
          GPFS: { cname: "GPFS/SpectrumScale", status: "", details: "" },
          Lustre: { status: "", details: "" },
          Isilon: { status: "", details: "" },
        },
        misc: {
          Gitlab: { status: "", details: "" },
          Etherpad: { status: "", details: "" },
          Privatebin: { status: "", details: "" },
        },
        infra: {
          Puppet: { status: "", details: "" },
          BlueBanquise: {
            cname: "Ansible/Bluebanquise",
            status: "",
            details: "",
          },
        },
      }
    },
    updateStatusData() {
      let self = this;
      let dataTicket = {};

      // Request opened tickets from Gitlab
      axios
        .get(STATUS_PROJECT_URL + "?state=opened", {
          headers: {
            "PRIVATE-TOKEN": STATUS_PROJECT_ACCESS_TOKEN,
          },
        })
        .then((response) => {
          // Clear displayed data
          self.clearStatusData();

          // For each ticket opened
          response.data.forEach((ticket) => {
            let labels = ticket.labels;

            // Checking if there's a 'status' label to apply
            let ticketStatus = labels.find((el) => el.includes("status::"));
            if (ticketStatus) {
              // Remove status label to prevent processing it later
              labels.filter((el) => el !== ticketStatus);
              // Store the result so we can use it later
              ticketStatus = ticketStatus.replace("status::", "");
            } else {
              // By default, a service is 'positive'
              ticketStatus = "positive";
            }

            // Checking if there's an 'announcement' label to apply
            let announcement = labels.find((el) => el.includes("announcement::"));
            if (announcement) {
              // Remove announcement label to prevent processing it later
              labels.filter((el) => el !== announcement);
              // Fill heading callout (banner) with information from the ticket we are processing
              self.headingCallout.status = announcement.replace(
                "announcement::",
                ""
              );
              self.headingCallout.title = ticket.title;
              self.headingCallout.text = ticket.description;
            }

            // Checking if there's an 'all' label to make all services impacted
            let labelAll = labels.find((el) => el.toLowerCase() == "all");
            if (labelAll) {
              // For each categories we declared in data()
              Object.keys(self.services).forEach((category) => {
                // For each services in category
                Object.keys(self.services[category]).forEach((service) => {
                  category = category.toLowerCase();
                  service = service.toLowerCase();

                  if (!dataTicket[category]) {
                    dataTicket[category] = {};
                  }
                  // We build dataTicket so we can set the status and the description to the associated service
                  dataTicket[category][service] = {
                    status: ticketStatus,
                    details: ticket.description,
                  };
                });
              });
            } else {
              ticket.labels.forEach((label) => {
                // From the label, we retrieve the service category and name (item)
                let labelSplitted = label.split("/");
                let category = labelSplitted[0];
                let name = labelSplitted[1];

                if (!dataTicket[category]) {
                  dataTicket[category] = {};
                }
                // We build dataTicket so we can set the status and the description to the associated service
                dataTicket[category][name] = {
                  status: ticketStatus,
                  details: ticket.description,
                };
              });
            }
          });

          // For each categories we declared in data()
          Object.keys(self.services).forEach((category) => {
            // Default category status is 'positive'
            self.categoryStatus[category] = "positive";

            // For each services in category
            Object.keys(self.services[category]).forEach((service) => {
              let lowerCasedCategory = category.toLowerCase();
              let lowerCasedService = service.toLowerCase();

              // If we have service defined in dataTicket
              // If not defined, there're no details and default status is 'positive'
              if (
                dataTicket[lowerCasedCategory] &&
                dataTicket[lowerCasedCategory][lowerCasedService]
              ) {
                // We retrieve the status stored in dataTicket, it'll be 'positive' otherwise
                let serviceStatus = dataTicket[lowerCasedCategory][
                  lowerCasedService
                ].status
                  ? dataTicket[lowerCasedCategory][lowerCasedService].status
                  : "positive";
                if (serviceStatus) {
                  self.services[category][service].status = serviceStatus;
                }
                // We retrieve details stored in dataTicket
                self.services[category][service].details =
                  dataTicket[lowerCasedCategory][lowerCasedService].details;

                // If the service isn't 'positive' or 'active', we try to set the category status with the status' one
                // We'll keep only the higher severity
                if (
                  serviceStatus &&
                  serviceStatus !== "positive" &&
                  serviceStatus !== "active"
                ) {
                  if (self.categoryStatus[category] !== "negative") {
                    self.categoryStatus[category] = serviceStatus;
                  }
                }
              } else {
                self.services[category][service].status = "positive";
              }
            });
          });
        })
        .catch(() => {
          // If we are not able to get tickets from the API
          self.headingCallout.status = "danger";
          self.headingCallout.title = "Unable to retrieve services status";
          self.headingCallout.text =
            "UL HPC facility can be under global maintenance (check your emails). Stay tuned and try again later.";
        });
    }
  }
};
</script>
<style>
</style>