<template>
  <div>
    <skewed-top></skewed-top>
    <markdown-page :content="content"></markdown-page>
  </div>
</template>
<script>
const latestHPCSchoolEdition = '2023-june'

export default {
  name: "components",
  components: {
  },
  metaInfo: {
    title: 'UL HPC School'
  },
  props: {
    edition: {
      type: String,
      default: latestHPCSchoolEdition
    }
  },
  data() {
    return {
      content: ''
    }
  },
  methods: {
    fetchData(edition) {
      import('!raw-loader!../../contents/education/hpcschool/' + edition + '.md')
        .then(result => this.content = result.default)
        .catch(err => this.$router.replace({ name: 'education-hpcschool' }));
    }
  },
  beforeRouteUpdate (to, from, next) {
    if(typeof to.params.edition === 'undefined'){
      this.fetchData(latestHPCSchoolEdition)
    } else {
      this.fetchData(to.params.edition)
    }
    next()
  },
  mounted(){
    this.fetchData(this.edition)
  }
};
</script>
<style>
</style>
