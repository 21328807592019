<template>
  <div>
    <skewed-top></skewed-top>
    <markdown-page :content="content"></markdown-page>
  </div>
</template>
<script>

import data from '!raw-loader!../../contents/infrastructure/Network.md';

export default {
  name: "components",
  components: {
  },
  metaInfo: {
    title: 'High Performance Networks'
  },
  data() {
    return {
      content: data
    }
  }
};
</script>
<style>
</style>
