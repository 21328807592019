<template>
    <div>
      <skewed-top></skewed-top>
      <div v-if="blogContent">
        <div class="container mt-4">
          <span class="pull-right"><i class="fas fa-calendar fa-sm"></i><small> {{ blogMetadata.date }}</small> <i class="fas fa-tags fa-sm"></i> <span class="badge bg-secondary" v-for="(tag, tagIndex) in blogMetadata.tags" :key="tagIndex">{{ tag }}</span></span>
          <h2>{{ blogMetadata.title }}</h2>
          <hr />
          <markdown-page :content="blogContent"></markdown-page>
          <br /><p>
            <em>Posted<span v-if="blogMetadata.authors"> by {{ blogMetadata.authors }}</span> on {{ this.getFormattedDate(blogMetadata.date) }}</em>
          </p>
          <div class="container mt-4 mb-1">
            <div class="row justify-content-md-center">
              <div class="col-md-5 mt-1">
                <router-link v-if="previousPost" :to="{ name: 'blog', params: { slug: previousPost.slug }}" class="btn btn-outline-primary d-block d-md-inline-block" role="button" >
                  <i class="fas fa-angle-double-left"></i> {{ previousPost.title }}
                </router-link>
              </div>
              <div class="col-md-5 offset-md-2 mt-1">
                <router-link v-if="nextPost" :to="{ name: 'blog', params: { slug: nextPost.slug }}" class="btn btn-outline-primary float-md-right d-block d-md-inline-block" role="button" >
                  {{ nextPost.title }} <i class="fas fa-angle-double-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="container mt-4">
          <h1>UL Research Computing and HPC operations Blog posts</h1>
          <h2 v-if="tagFilter.length > 0">
            <router-link :to="{ name: 'blog'}" tag="div" style="cursor: pointer">
              <span class="badge bg-secondary">
                <i class="fas fa-times fa-sm"></i>
                <span v-for="(tag, tagIndex) in tagFilter" :key="tagIndex" style="margin-left: 8px">{{ tag }}</span>
              </span>
            </router-link>
          </h2>
          <hr />
          <div class="table-responsive">
            <table class="table">
                <tbody v-for="year in sortedBlogEntrieskeys" :key="year">
                    <tr class="d-none d-lg-table-row">
                        <td :rowspan="filteredBlogEntriesList[year].length+1" style="border: none"><h2>{{ year }}</h2></td>
                    </tr>
                    <tr v-for="(blogPost, blogIndex) in filteredBlogEntriesList[year]" :key="blogIndex">
                        <td>
                            <router-link :to="{ name: 'blog', params: { slug: blogPost.slug }}" style="font-size: 2em">
                              {{ blogPost.title }}
                            </router-link><br />
                            <span class="pull-left"><i class="fas fa-calendar fa-sm"></i><small> {{ blogPost.date }}</small></span>
                            <span class="pull-right"><i class="fas fa-tags fa-sm"></i>
                              <span class="badge bg-secondary" v-for="(tag, tagIndex) in blogPost.tags" :key="tagIndex">
                                <router-link :to="{ name: 'blog', query: { tags: tag + ',' + tagFilter }}" tag="span" style="cursor: pointer">
                                  {{ tag }}
                                </router-link>
                              </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
          <p>Click <a href="https://hpc.uni.lu/old/blog/archives/" target="_blank">here</a> to see older blog posts (before 2021)</p>
        </div>
      </div>
    </div>
</template>
<script>
import blogEntriesList from "../contents/blog/index.json";

export default {
  name: "components",
  components: {
  },
  metaInfo() {
    let title = 'Blog';
    if(this.blogMetadata) {
      title = this.blogMetadata.title;
    }
    return {
       title: title
    }
  },
  props: {
    slug: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      blogContent: '',
      blogEntriesList: blogEntriesList
    }
  },
  methods: {
    fetchData(slug) {
      if(slug.startsWith('redirect!')){
        this.$router.replace({ name: slug.replace(/redirect!/gi, '') });
      } else {
        import('!raw-loader!../contents/blog/' + slug + '.md')
          .then(result => this.blogContent = result.default)
          .catch(err => this.$router.replace({ name: 'blog' }));
      }
    },
    getBlogMetadataBySlug(slug) {
      if(!slug){
        return null;
      }
      for (const [yearKey, yearObject] of Object.entries(this.blogEntriesList.posts)) {
        for (const [key, blogObject] of Object.entries(yearObject[Object.keys(yearObject)[0]])) {
          if(blogObject.slug === slug) {
            return blogObject;
          }
        }
      }
      return null;
    },
    getNavigationPosts(slug) {
      if(!slug){
        return {
          "previousPost": null,
          "nextPost": null
        }
      }
      let nextPost = null;
      let isNextPostFounded = false;
      for (const [yearKey, yearObject] of Object.entries(this.blogEntriesList.posts)) {
        for (const [key, blogObject] of Object.entries(yearObject[Object.keys(yearObject)[0]])) {
          if(blogObject.slug === slug) {
            isNextPostFounded = true;
          } else {
            if(isNextPostFounded) {
              return {
                "previousPost": blogObject,
                "nextPost": nextPost
              }
            } else {
              nextPost = blogObject;
            }
          }
        }
      }
      return {
        "previousPost": null,
        "nextPost": nextPost
      }
    },
    getFormattedDate(date) {
      let result = new Date(date);
      return result.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
  },
  computed: {
    blogMetadata: function() {
      return this.getBlogMetadataBySlug(this.slug);
    },
    nextPost: function() {
      return this.getNavigationPosts(this.slug).nextPost;
    },
    previousPost: function() {
      return this.getNavigationPosts(this.slug).previousPost;
    },
    tagFilter: function() {
      if(this.$route.query.tags) {
        return this.$route.query.tags.split(',').filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      }
      return [];
    },
    filteredBlogEntriesList: function() {
      let res = {};

      function insertPostIntoRes(yearObject, blogPost) {
        if(!res.hasOwnProperty(Object.keys(yearObject)[0])) {
          res[Object.keys(yearObject)[0]] = [];
        }
        res[Object.keys(yearObject)[0]].push(blogPost);
      }

      this.blogEntriesList.posts.forEach((yearObject) => {
        yearObject[Object.keys(yearObject)[0]].forEach((blogPost) => {
          if(this.tagFilter.length > 0) {
            if (this.tagFilter.some(tag => blogPost.tags.includes(tag))) {
              insertPostIntoRes(yearObject, blogPost);
            }
          } else {
            insertPostIntoRes(yearObject, blogPost);
          }
        });
      });

      return res;
    },
    sortedBlogEntrieskeys: function() {
      return Object.keys( this.filteredBlogEntriesList ).sort( ( a , b ) => b - a );
    }
  },
  beforeRouteUpdate (to, from, next) {
    if(to.params.slug){
      this.fetchData(to.params.slug)
    } else {
      this.blogContent = ''
    }
    next()
  },
  mounted(){
    if(this.slug) {
      this.fetchData(this.slug)
    }
  }
};
</script>
<style>
</style>
